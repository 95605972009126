import { TMessage, TSourceDetail } from '@core/interfaces/TMessage';

import { TSourceMaterial } from '@core/api/materials/types';
import {
  TAdaptedMessage,
  TAdaptedParagraph,
  TAdaptedReference,
} from '@shared/services/bots/types/types';
import { ReferencesUtils } from '@shared/utils/referencesUtils';
import { TReferencesStream } from '@core/api/conversations/types';
import { ArrayUtils } from '@shared/utils/arrayUtils';

export class MessageParser {
  private static readonly separator = '{{resources}}';

  static splitTextAndMaterials(message: TMessage): TAdaptedMessage {
    const results: TAdaptedMessage = {
      _id: message._id,
      date: message.date,
      author: message.type,
      paragraphs: [],
      sources: message.sourceDetails ?? [],
      favorite: message.favorite,
      originalSourceDetails: [],
      originalQuestion: '',
      type: 'message'
    } as TAdaptedMessage;

    let currentText: TAdaptedParagraph = { text: '', materials: [] };

    message.text
      .split(this.separator)
      .filter(item => item !== '')
      .forEach(value => {
        if (value.includes('fileType')) {
          if (!currentText.materials) {
            currentText.materials = [];
          }
          currentText.materials.push(JSON.parse(value) as TSourceMaterial);
        } else {
          currentText = { text: value, materials: [] };
          results.paragraphs.push(currentText);
        }
      });

    return results;
  }

  static parseMaterialsWithReferences(message: TMessage): TAdaptedMessage {
    const splittedTextAndMaterials = this.splitTextAndMaterials(message);

    const result: TAdaptedMessage = {
      author: splittedTextAndMaterials.author,
      date: splittedTextAndMaterials.date,
      paragraphs: [],
      originalSourceDetails: message.sourceDetails ?? [],
      sources: [],
      files: [],
      type: 'message',
      favorite: splittedTextAndMaterials.favorite,
      _id: message._id,
    };

    splittedTextAndMaterials.paragraphs.forEach(item => {
      const retrievedData = this.retrieveReferencesFromParagraph(message.sourceDetails ?? [], item);
      result.paragraphs.push(...retrievedData);
    });

    result.sources = this.filterSources(result, splittedTextAndMaterials.sources);

    return result;
  }

  static retrieveReferencesFromParagraph(
    sources: TSourceDetail[],
    adoptedParagraph: TAdaptedParagraph
  ): TAdaptedParagraph[] {
    const parts = adoptedParagraph.text.match(/{{(\d+)(\|\d+)?}}|[^{]+|^{/g);

    const result: TAdaptedParagraph[] = [];

    if (!parts) return result;

    let it: TAdaptedParagraph = { text: '', references: [] };

    for (let i = 0; i < parts!.length; i++) {
      if (parts[i] === ' ') {
        continue;
      }

      if (/{{(\d+)(\|\d+)?}}/.test(parts![i])) {
        const { ref, number } = MessageParser.extractReference(parts![i]);
        it.references!.push({
          order: number,
          refIndex: ref - 1,
          source: sources[ref - 1],
          link: ReferencesUtils.getLink(sources[ref - 1]),
        });
      } else {
        it = {
          text: parts![i],
          references: [],
        };
        result.push(it);
      }
    }
    result[result.length - 1].materials = adoptedParagraph.materials;
    result.map(par => {
      par.references = ArrayUtils.sortUniqueBy(par.references ?? [], 'order');
    });
    return result;
  }



  private static extractReference(parts: string) {
    const splitParts = parts.replace('{{', '').replace('}}', '').split('|');
    const ref = parseInt(splitParts[0]);
    const number = splitParts[1] ? parseInt(splitParts[1]) : ref;
    return { ref, number };
  }

  static filterSources(message: TAdaptedMessage, sources: TSourceDetail[]): TSourceDetail[] {
    const sourcesIndexes = message.paragraphs.flatMap(text => {
      return (
        text.references?.map(source => {
          return source.refIndex;
        }) || []
      );
    });
    return [...new Set(sourcesIndexes)].map(index => {
      return sources[<number>index];
    });
  }
}
