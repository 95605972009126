import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

import { ContentItemType } from '@core/interfaces/ContentItemType';
import { Endpoints, TSource } from '@core/interfaces/Endpoints';
import { TSourceItem } from '@core/interfaces/TSourceItem';

import { CommonService } from '@shared/services/common.service';
import { FoldersService } from '@shared/services/folders.service';
import { SourcesApi } from '@core/api/sources/api';
import { TExternalReference, TSourceEmbedding } from '@core/api/sources/types';
import { IGNORE_ERRORS_KEY } from "@core";

interface FileBody {
  file: File;
  sourceId: string;
}

interface NewFileResponse {
  message: string;
  source: TSourceItem;
}

@Injectable({
  providedIn: 'root',
})
export class SourcesService {
  source: BehaviorSubject<TSourceItem | undefined> = new BehaviorSubject<TSourceItem | undefined>(
    undefined
  );
  private $cancelRequest = new Subject<void>();

  constructor(
    private _http: HttpClient,
    private _commonService: CommonService,
    private _folderService: FoldersService
  ) {}

  public addNewSourceFile(
    body: FileBody,
    callback: (res: NewFileResponse) => void,
    errorCallback: (err: any) => void
  ) {
    this._commonService.setIsLoading(true);
    const formData = new FormData();
    formData.append('file', body.file);
    formData.append('sourceId', body.sourceId);
    const response = this._http.post<NewFileResponse>(
      Endpoints.sources.createSourceFile.url,
      formData
    );
    response.pipe(takeUntil(this.$cancelRequest)).subscribe({
      error: err => {
        console.log(err);
        errorCallback(err);
        this._commonService.setIsLoading(false);
      },
      next: res => {
        this._folderService.setFolderSources(res.source);
        callback(res);
      },
      complete: () => {
        this._commonService.setIsLoading(false);
      },
    });
  }

  public cancelRequest() {
    this.$cancelRequest.next();
  }

  public updateSource(source: Partial<TSource>, callback: () => void): void {
    this._commonService.setIsLoading(true);
    const response = this._http.put<TSource>(
      Endpoints.sources.updateSource.url(source._id ?? ''),
      source
    );
    response.subscribe({
      error: err => {
        console.log(err);
      },
      next: response => {
        this._folderService.regenerateEmbeddings(response, callback);
      },
      complete: () => {
        this._commonService.setIsLoading(false);
      },
    });
  }

  getSource(sourceId: string, callback: (source: TSourceItem) => void): void {
    const response = this._http.get<TSourceItem>(Endpoints.sources.getSource.url(sourceId));
    response.subscribe({
      error: err => {
        console.log(err);
      },
      next: res => {
        const source = {
          ...res,
          __type: ContentItemType.Sources,
        };
        this.source.next(source);
        callback(source);
      },
    });
  }



  addExternalReference(sourceId: string, reference: TExternalReference) {
    const { url, body } = SourcesApi.addExternalReference(sourceId, reference);
    return this._http.post(url, body);
  }
  updateExternalReference(sourceId: string, reference: TExternalReference) {
    const { url, body } = SourcesApi.updateExternalReference(sourceId, reference);
    return this._http.put(url, body);
  }

  searchExternalReference(sourceId: string, query: string) {
    const { url } = SourcesApi.searchReference(sourceId, query);
    return this._http.get<TExternalReference[]>(url);
  }

  deleteExternalReference(sourceId: string, referenceId: string) {
    const { url } = SourcesApi.deleteExternalReference(sourceId, referenceId);
    return this._http.delete(url);
  }

  getEmbeddings(sourceId: string) {
    const { url } = SourcesApi.getEmbeddings(sourceId);
    const headers = new HttpHeaders().set(IGNORE_ERRORS_KEY, 'true');
    return this._http.get<TSourceEmbedding[]>(url, { headers });
  }

  attachReference(embeddingId: string, referenceId: string) {
    const { url, body } = SourcesApi.attachReference(embeddingId, referenceId);
    return this._http.post(url, body);
  }

  detachReference(embeddingId: string, referenceId: string) {
    const { url } = SourcesApi.detachReference(embeddingId, referenceId);
    return this._http.delete(url);
  }
}
