<pre *ngIf="referenceSources.length > 0">{{ referenceLabel }}:</pre>
<div class="m-t-8 d-flex references-content" *ngFor="let rsrc of referenceSources; index as i">
  <span>
    <span>{{ i + 1 }}.
      <app-source-preview-button
        *ngFor="let ref of (rsrc.referenceIndexes ? Array.from(rsrc.referenceIndexes.entries()) : [])"
        [label]="ref[0]"
        [sourceDetail]="rsrc"
        [highlightText]="ref[1]"
      >
      </app-source-preview-button>

      <a *ngIf="rsrc" [href]="getSourceLink(rsrc)" target="_blank">{{ rsrc.references || rsrc.name }}</a>
      <app-source-preview-button class="m-l-8" [sourceDetail]="rsrc"></app-source-preview-button>
    </span>
  </span>
</div>
