<div class="d-flex justify-content-center">
  <app-source-references-search
    [source]="source"
    (results)="setResults($event)"
  >
  </app-source-references-search>
</div>
<div class="scrollable-content m-y-24">
  <table mat-table [dataSource]="source?.externalReferences ?? []">

    <!-- Reference Text Column -->
    <ng-container matColumnDef="referenceText">
      <th mat-header-cell *matHeaderCellDef> Reference Text</th>
      <td mat-cell *matCellDef="let ref"> {{ ref.referenceText }}</td>
    </ng-container>

    <!-- Reference Link Column -->
    <ng-container matColumnDef="referenceLink">
      <th mat-header-cell *matHeaderCellDef> Reference Link</th>
      <td mat-cell *matCellDef="let ref"> {{ ref.referenceLink }}</td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Actions</th>
      <td mat-cell *matCellDef="let ref">
        <div class="d-flex">

          <div *ngIf="sourceEmbedding">
            <button *ngIf="isAttached(ref); else detach" [disabled]="isLoading" mat-flat-button color="accent"
                    (click)="attachReference(ref)" class="m-r-8">
              Attach
            </button>
            <ng-template #detach>
              <button [disabled]="isLoading" mat-flat-button color="primary"
                      (click)="detachReference(ref)" class="m-r-8">
                Detach
              </button>
            </ng-template>
          </div>
          <button [disabled]="isLoading" mat-flat-button color="warn" (click)="deleteReference(ref)" class="m-r-8">Delete</button>
          <button [disabled]="isLoading" mat-flat-button color="primary" (click)="openModal(ref)">Edit</button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['referenceText', 'referenceLink', 'actions']"></tr>
    <tr mat-row *matRowDef="let row; columns: ['referenceText', 'referenceLink', 'actions'];"></tr>
  </table>
</div>

<button mat-raised-button color="accent" (click)="openModal()">
  <mat-icon>add</mat-icon>
  Add new
</button>
