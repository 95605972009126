import { Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { FileStorageService } from '@shared/services/file-storage.service';
import { TAdaptedBotConversation } from '@shared/services/bots/types/types';
import { TConversationVideoItem } from '@core/api/conversations/types';
import { ConversationsService } from '@shared/services/conversations.service';
import { TruncatePipe } from '@shared/pipes/truncate.pipe';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-talking-avatar-playlist',
  templateUrl: './talking-avatar-playlist.component.html',
  styleUrls: ['./talking-avatar-playlist.component.scss'],
  providers: [TruncatePipe],
})
export class TalkingAvatarPlaylistComponent implements OnDestroy {
  protected videos: TConversationVideoItem[] = [];
  protected videoPlaceHolder = '/assets/avatar/prev.mov';
  protected videoData = {
    url: this.videoPlaceHolder,
    loop: true,
  };
  protected activeVideoIndex: number | null = null;
  private _subs: Subscription[] = [];

  constructor(
    private storageService: FileStorageService,
    private conversationService: ConversationsService,
    private truncatePipe: TruncatePipe
  ) {}

  @Input() set conversation(conversation: TAdaptedBotConversation | null) {
    this.videos = [];
    if (conversation) {
      this._subs.push(
        this.conversationService.getVideosForConversation(conversation.id!).subscribe(value => {
          this.videos = value.avatarVideos.map(video => {
            const message = conversation.messages.find(msg => msg._id === video.messageId);
            return {
              ...video,
              displayTitle: this.truncatePipe.transform(message?.paragraphs[0].text ?? '', 200),
            };
          });
        })
      );
    }
  }

  @ViewChild('video') video: ElementRef<HTMLVideoElement> | undefined;

  getVideo(id: string, index: number) {
    this.activeVideoIndex = index;
    this.storageService.download(id).subscribe(blob => {
      this.videoData = {
        url: URL.createObjectURL(blob),
        loop: false,
      };
    });
  }

  onVideoEnded() {
    if (this.videoData.url !== this.videoPlaceHolder) {
      this.videoData = {
        url: this.videoPlaceHolder,
        loop: true,
      };
    }
    this.activeVideoIndex = null;
  }

  download(id: string) {
    this._subs.push(
      this.storageService.download(id).subscribe(blob => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = id;
        a.click();
        URL.revokeObjectURL(url);
      })
    );
  }

  ngOnDestroy() {
    this._subs.forEach(sub => sub.unsubscribe());
  }
}
