<app-spinner *ngIf="botMessagesService.isLoading"></app-spinner>

<div *ngIf="!botMessagesService.isLoading">
  <app-talking-avatar-playlist *ngIf="showTalkingAvatar" [conversation]="adaptedBotConversation"></app-talking-avatar-playlist>
  <div class="toolbar-container" *ngIf="adaptedBotConversation?.messages">
    <app-star-button
      [canStar]="canStar"
      [isStarred]="adaptedBotConversation!.favorite"
      [onToggle]="toggleStarConversation.bind(this)"
    ></app-star-button>
    <app-export-to-selector [isDisabled]="botMessagesService.isWriting"></app-export-to-selector>
  </div>

  <div *ngFor="let message of adaptedBotConversation?.messages; trackBy: trackByMessage">
    <div class="message-{{ message.author }} {{message.type}}">
      <app-message-controls [message]="message" [bot]="bot"
                            [hideControls]="['error', 'quiz'].includes(message.type)"></app-message-controls>
      <div class="message-container">
        <app-single-message [isLoading]="botMessagesService.isWriting" [message]="message"></app-single-message>
        <p *ngIf="botFinalMessage && message.author === 'assistant' && !message?.isWriting">
          {{ botFinalMessage }}
        </p>
        <app-bot-quiz-answers [conversationId]="conversationId" *ngIf="message.paragraphs[0].quizQuestions"
                              [message]="message"></app-bot-quiz-answers>
        <app-references
          *ngIf="!['error', 'quiz'].includes(message.type)"
          [lang]="adaptedBotConversation!.language"
          [sources]="message.sources"
        ></app-references>
        <app-spinner
          [small]="true"
          *ngIf="message?.isWriting"
          [message]="'Bot is writing...'"
        ></app-spinner>
      </div>
    </div>
  </div>

  <app-conversation-input
    [fixedToBottom]="true"
    [isExtended]="false"
    [isDisabled]="botMessagesService.isLoading"
    (messageSubmitted)="continueConversation($event.message)"
  ></app-conversation-input>
</div>
