import { ElementRef, Injectable } from '@angular/core';
import { FileStorageService } from '@shared/services/file-storage.service';
import { Observable } from 'rxjs';
import { ConversationsService } from '@shared/services/conversations.service';

@Injectable({
  providedIn: 'root',
})
export class RecordStreamService {
  private videoElement: ElementRef<HTMLVideoElement> | undefined = undefined;
  private mediaRecorder: MediaRecorder | undefined;
  private recordedChunks: Blob[] = [];
  private ids: { conversationId: string; messageId: string } | undefined;

  constructor(
    private storageService: FileStorageService,
    private conversationService: ConversationsService
  ) {}

  setVideoElement(videoElement: ElementRef<HTMLVideoElement> | undefined) {
    this.videoElement = videoElement;
  }

  public setIds(conversationId: string, messageId: string) {
    this.ids = { conversationId, messageId };
  }

  startRecording() {
    this.recordedChunks = [];
    if (this.videoElement?.nativeElement.srcObject instanceof MediaStream) {
      this.mediaRecorder = new MediaRecorder(this.videoElement.nativeElement.srcObject, {
        mimeType: 'video/mp4'
      });
      this.mediaRecorder.ondataavailable = event => {
        if (event.data.size > 0) {
          this.recordedChunks.push(event.data);
        }
      };
      this.mediaRecorder.start();
    }
  }

  stopRecording(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.mediaRecorder) {
        this.mediaRecorder.onstop = () => {
          const blob = new Blob(this.recordedChunks, { type: 'video/mp4' });
          const file = new File([blob], 'recording.mp4', { type: 'video/mp4' });
          this.uploadFile(file).subscribe({
            next: value => {
              if (value && this.ids) {
                this.updateAvatarVideo(value.fileKey).subscribe({
                  next: () => {
                    resolve();
                  },
                  error: err => {
                    console.error(err);
                    reject(err);
                  },
                });
              } else {
                resolve();
              }
            },
            error: err => reject(err),
          });
        };
        setTimeout(() => {
          this.mediaRecorder!.stop();
        }, 1000); // Delay stopping the recording by 1 second
      } else {
        resolve();
      }
    });
  }

  protected uploadFile(file: File) {
    return this.storageService.upload(file);
  }

  protected updateAvatarVideo(avatarVideo: string) {
    if (this.ids) {
      return this.conversationService.updateAvatarVideo(
        avatarVideo,
        this.ids.conversationId,
        this.ids.messageId
      );
    }
    return new Observable();
  }
}
