import { Component, Input } from '@angular/core';
import { TSourceDetail } from '@core/interfaces/TMessage';
import { FoldersService } from '@shared/services/folders.service';
import { MatDialog } from '@angular/material/dialog';
import { SourcePreviewModalComponent } from '../modal/source-preview-modal.component';
import { TSourceItem } from '@core/interfaces/TSourceItem';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-source-preview-button',
  templateUrl: './source-preview-button.component.html',
  styleUrls: ['./source-preview-button.component.scss'],
})
export class SourcePreviewButtonComponent {
  @Input() sourceDetail: TSourceDetail | null = null;
  @Input() highlightText: string | undefined = undefined;
  @Input() label: string | number | null = 'Read more';
  protected isLoading = false;

  constructor(
    private _foldersService: FoldersService,
    private dialog: MatDialog,
  ) {
  }

  protected onButtonClick() {
    const sourceSubject = new BehaviorSubject<TSourceItem>({} as TSourceItem);
    const data = {
      source: sourceSubject,
      markText: this.sourceDetail?.embeddingContent ?? this.highlightText,
    };

    this.dialog.open(SourcePreviewModalComponent, {
      data,
    });

    this._foldersService.getSource(this.sourceDetail!._id!, res => {
      data.source.next(res);
    });
  }


}
