<div class="modal">
  <form class="form-field-full" [formGroup]="referenceForm" (ngSubmit)="submit()">
    <h3> {{ data.reference ? 'Update reference' : 'Add new reference' }}</h3>
    <div class="m-t-24 flex-col">
      <div class="d-flex flex-col m-r-48">
        <div class="d-flex justify-content-between w-full m-b-16">
          <div class="item">Text</div>
          <div class="field">
            <mat-form-field class="gingr-input-wrapper w-full hide-sub ">
              <input matInput formControlName="referenceText" type="text" required/>
            </mat-form-field>
          </div>
        </div>
        <div class="d-flex justify-content-between w-full m-b-24">
          <div class="item">Link</div>
          <div class="field">
            <mat-form-field class="gingr-input-wrapper w-full hide-sub ">
              <input matInput formControlName="referenceLink" type="text" required/>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-between m-t-16">
      <button
        mat-raised-button
        color="accent"
        (click)="cancel()">
        Cancel
      </button>
      <button
        mat-raised-button
        color="accent"
        type="submit"
        [disabled]="!referenceForm.valid"
      >
        {{ data.reference ? 'Update' : 'Create' }}
      </button>
    </div>
  </form>
</div>
