import { Language } from './language';
import { TSourceDetail } from '@core/interfaces/TMessage';

export type BotType = `Bot1` | `Bot2` | `Bot3`;
// TODO: clean up when types are changed on the backend
export const PredefinedBotTypes = [
  'emailBot',
  'chatBot',
  'trainingBot',
  'summaryBot',
  'quizBot',
  'compareBot',
] as const;
export type PredefinedBotType = (typeof PredefinedBotTypes)[number];
export type BotCreatorType = 'botCreator';
export type BotCreatorItemType = 'creatorItem';
export type BotTypeId = PredefinedBotType | BotCreatorType | BotCreatorItemType;
export type TMainBotType = PredefinedBotType | BotCreatorType;
// TODO: clean up when types are changed on the backend
export const allowedNewBotTypes: string[] = ['summaryBot', 'quizBot', 'compareBot', 'botCreator'];

export interface TBot {
  icon: string;
  type: BotType;
  bot_status?: BotStatusTypes;
  description: string;
}

export interface TBotInstruction {
  slug: string;
  value: string;
  name: string;
}

export type TAskBotBody =
  | TAskBotConversationBody
  | TAskBotSummaryBody
  | TCompareBotBody
  | TCompareManyBotBody
  | TQuizBotInitBody
  | TQuizBotQuestionsBody
  | TQuizBotFeedback;

export interface TAskBotConversationBody {
  conversation_id: string;
  question: string;
  project_id?: string;
}

export interface TAskBotSummaryBody {
  sourcesIds: string[];
}

export interface TCompareBotBody {
  source_id_1: string;
  source_id_2: string;
}

export interface TCompareManyBotBody {
  sourcesIds: string[];
}

export interface TQuizBotInitBody {
  question: string;
  request_type: 'get_init_message';
  files?: File[];
}

export interface TQuizBotQuestionsBody {
  conversation_id: string;
  content: string;
  request_type: 'get_question';
}

export interface TQuizBotFeedback {
  conversation_id: string;
  request_type: 'get_feedback';
}

export interface TBotCreatorBody {
  name: string;
  language: Language;
  description?: string;
  instruction?: string;
  behaviour?: string;
  config?: string;
  bot_status?: string;
  botModel?: string;
  display_resources?: boolean;
  showOnDashboard?: boolean;
  maximum_resources?: number;
  promptHints?: string[];
  maximum_publications?: number;
  preSearch?: string;
}

export type TBotCreator = TBotCreatorBody & {
  _id: string;
  bots: TInnerBot[];
  bot_status: BotStatusTypes;
};
export type TInnerBot = TInnerBotBody & { _id: string };

export interface TInnerBotBody {
  name: string;
  description?: string;
  preSearch?: string;
  instruction?: string;
  behaviour?: string;
  config?: string;
  top_p?: number;
  presence_penalty?: number;
  frequency_penalty?: number;
  temperature?: number;
  display_resources?: boolean;
  showOnDashboard?: boolean;
}

export interface TBotResponseSource {
  name: string;
  embedText: string;
  priority: number;
  _id: string;
  references?: string;
  fileLink?: string;
  folder: string[];
}

export interface TBotResponse {
  sources?: TBotResponseSource[];
  answer: string;
  conversation_id: string | null;
  userDate: string;
  content?: string;
  json_answer?: QuizQuestion[];
}

export interface TBotItemRequestBody {
  name: string;
  botType: string;
  status: string;
  botModel: string;
}

export interface TBotConfigField {
  slug: string;
  displayName: string;
  default?: string;
}

export interface TBotType {
  id: string;
  name: string;
  description: string;
  version: string;
  configurationFields: TBotConfigField[];
  instructionsFields: TBotConfigField[];
}

export interface TAddBotResponse {
  botType: string;
  bot_status?: BotStatusTypes;
  botModel?: string;
  configuration: [];
  config?: string;
  instructions: [];
  description?: string;
  name: string;
  _id?: string;
  display_resources?: boolean;
  showOnDashboard?: boolean;
  extraConfigurations?: TBotExtraConfigurations;
  maximum_resources?: number;
  promptHints?: string[];
  maximum_publications?: number;
  preSearch?: string;
}

export interface TLanguage {
  code: string;
  name: string;
}

export interface IAddBotCreatorResponse {
  botsCreator: IBotsCreator;
  bot: TAddBotResponse & { botsCreator: string };
}

export interface IBotsCreator {
  name: string;
  language: Language;
  description: string;
  behaviour: string;
  bots: TInnerBot[];
  _id: string;
}

export interface QuizQuestion {
  question: string;
  answers: QuizAnswer[];
  disabled?: boolean;
  sourceDetails: TSourceDetail[];
  correctAnswered?: boolean;
  good_answer_reply: string;
  wrong_answer_reply: string;
}

export interface QuizAnswer {
  answer: string;
  correct: boolean;
  selected?: boolean;
}

const statuses = ['in_validation', 'validated', 'decommissioned', 'issue'];

export type BotStatusTypes = (typeof statuses)[number];
export const botStatuses: BotStatusTypes[] = statuses;

export enum BotEngineEnum {
  Amazon = 'amazon',
  Azure = 'azure',
}

export interface TBotExtraConfigurations {
  feedback: {
    behaviour: string;
    instruction: string;
    config: string;
  };
  initMessage: {
    behaviour: string;
    instruction: string;
    config: string;
  };
  secondRun: {
    config: string;
  };
}
