<div class="header">

  <button mat-raised-button color="accent" (click)="sourceFileInput.click()">
    <mat-icon>add</mat-icon>
    Upload file
  </button>
  <input
    hidden
    (change)="uploadFileCallback($event)"
    #sourceFileInput
    type="file"
    id="sourceFileInput"
  />
</div>

<div [ngClass]="getTagCSS(selectedItem.status)">
  {{ getTagLabel(selectedItem.status) }}
</div>
<form class="form-field-full" [formGroup]="sourceForm">
  <div class="m-t-24 flex-col source-data">
    <div class="d-flex flex-col m-r-48">
      <div class="d-flex justify-content-between w-full">
        <div class="item">Filename</div>
        <div class="item field">
          <mat-form-field class="gingr-input-wrapper w-full">
            <input formControlName="name" matInput type="text" required />
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex justify-content-between w-full m-b-24">
        <div class="item">Status</div>
        <div class="item field">
          <mat-chip-listbox aria-label="Status" formControlName="status">
            <mat-chip-option
              [value]="allowedStatuses.ACTIVE"
              color="{{ selectedItem.status === allowedStatuses.ACTIVE && 'accent' }}"
              selected="{{ selectedItem.status === allowedStatuses.ACTIVE }}"
              >{{ allowedStatuses.ACTIVE | titlecase }}</mat-chip-option
            >
            <mat-chip-option
              [value]="allowedStatuses.INACTIVE"
              color="{{ selectedItem.status === allowedStatuses.INACTIVE && 'accent' }}"
              selected="{{ selectedItem.status === allowedStatuses.INACTIVE }}"
              >{{ allowedStatuses.INACTIVE | titlecase }}</mat-chip-option
            >
          </mat-chip-listbox>
        </div>
      </div>
      <div class="d-flex justify-content-between w-full">
        <div class="item">Publication Date</div>
        <div class="item field">
          <mat-form-field class="gingr-input-wrapper w-full">
            <input
              formControlName="publicationDate"
              matInput
              [matDatepicker]="picker"
              placeholder="Choose a date"
            />
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <div class="d-flex justify-content-between w-full">
        <div class="item">Priority</div>
        <div class="item field">
          <mat-form-field class="gingr-input-wrapper w-full">
            <mat-select formControlName="priority" placeholder="Select a priority level">
              <mat-option *ngFor="let val of [1, 2, 3, 4, 5]" [value]="val">
                {{ getPriorityLabel(val) }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="d-flex justify-content-between w-full">
        <div class="item">Country</div>
        <div class="item field">
          <mat-form-field class="gingr-input-wrapper w-full">
            <mat-select placeholder="Select country" formControlName="countries" multiple>
              <mat-option
                *ngFor="let value of getCountriesAndRegions(); let i = index"
                [value]="value._id"
              >
                {{ value.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="d-flex m-b-24">
        <div class="item">Content</div>
        <div class="item field">
          <button mat-raised-button color="accent" (click)="openContentModal()">
            {{ !selectedItem.content ? 'Add content' : 'Show content' }}
          </button>
          <button mat-raised-button class="m-l-16" color="accent" (click)="openConfirmationModal()">
            Regenerate embeddings
          </button>
        </div>
      </div>
      <div class="d-flex">
        <div class="item">References</div>
        <div class="item field item--large-h item--large-w">
          <mat-form-field class="gingr-input-wrapper w-full">
            <textarea matInput class="" formControlName="references"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex">
        <div class="item item--with-button">
          <span>Metadata</span>
          <button mat-icon-button color="accent" (click)="addNewMetadata()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
        <div *ngIf="metadata.controls.length > 0" class="item field m-t-8 d-flex flex-col">
          <label>Metadata</label>
          <div formArrayName="metadata">
            <div *ngFor="let item of metadata.controls; let i = index" [formGroupName]="i" class="d-flex m-b-8">
              <mat-form-field class="gingr-input-wrapper w-full m-r-8">
                <input matInput formControlName="slug" placeholder="Slug" />
              </mat-form-field>
              <mat-form-field class="gingr-input-wrapper w-full m-r-8">
                <input matInput formControlName="value" placeholder="Value" />
              </mat-form-field>
              <button mat-icon-button color="accent" (click)="removeMetadata(i)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex m-b-24 m-t-24">
        <div class="item">File</div>
        <div class="item field">
          {{ selectedItem.fileUrl || 'No file uploaded' }}
        </div>
      </div>
      <div class="d-flex">
        <div class="item">File link</div>
        <div class="item field d-flex align-items-center file-link">
          <a
            *ngIf="selectedItem.fileLink && !isFileLinkEditing"
            href="{{ selectedItem.fileLink }}"
            target="_blank"
            >{{ selectedItem.fileLink }}</a
          >
          <mat-form-field
            *ngIf="!selectedItem.fileLink || isFileLinkEditing"
            class="gingr-input-wrapper w-full"
          >
            <input formControlName="fileLink" matInput type="text" />
          </mat-form-field>
          <mat-icon (click)="toggleFileLinkEditing()">edit</mat-icon>
        </div>
      </div>
    </div>
  </div>
</form>

<div class="m-t-48 p-48 files-footer">
  <button mat-raised-button color="primary" (click)="back()">Cancel</button>
  <button mat-raised-button color="accent" (click)="saveSource()">Save</button>
</div>
