import { TAdaptedBotConversation } from '../types/types';
import { BaseAdapter } from '@shared/services/bots/adapters/BaseAdapter';
import { ArrayUtils } from '@shared/utils/arrayUtils';
import { TSourceDetail } from '@core/interfaces/TMessage';
import { TSourceMaterial } from '@core/api/materials/types';
import { TConversationStreamData } from '@core/api/conversations/types';
import { MessageParser } from '@shared/services/bots/helpers/messageParser';

export class BotConversationAdapter extends BaseAdapter {
  adapt(
    data: TConversationStreamData,
    currentValue: TAdaptedBotConversation,
    sourcesDetails: TSourceDetail[]
  ): TAdaptedBotConversation {
    currentValue.type = 'conversation';
    const lastMessage = ArrayUtils.last(currentValue?.messages);
    let lastParagraph = ArrayUtils.last(lastMessage?.paragraphs);
    if (!lastParagraph) {
      lastParagraph = { text: '', materials: [], references: [] };
      lastMessage?.paragraphs.push(lastParagraph);
    }

    switch (data.type) {
      case 'message':
        lastParagraph.text += <string>data.data;
        break;
      case 'material':
        lastParagraph.materials?.push(<TSourceMaterial>data.data);
        break;
      case 'message_id':
        // at the end on message, if last paragraph is empty, remove it
        if (
          lastParagraph.text === '' &&
          !lastParagraph.materials?.length &&
          !lastParagraph.references?.length
        ) {
          lastMessage?.paragraphs.pop();
        }
        break;
    }

    if (lastMessage) {
      lastMessage.sources = MessageParser.filterSources(lastMessage, sourcesDetails);
      lastMessage.originalSourceDetails = sourcesDetails;
    }
    return currentValue;
  }
}
