import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Subscription } from 'rxjs';
import { IGNORE_ERRORS_KEY } from '@core';
import { TalkingAvatarApi } from '@core/api/talking-avatar/api';
import { TTalkingAvatarConfig } from '../../components/bot-editor/talking-avatar-settings/types';
import { TBotItem } from '@core/interfaces/TBotItem';
import { MetadataService, MetadataSlugs } from '@shared/services/metadata.service';
import StreamingAvatar, {
  AvatarQuality,
  StreamingEvents,
  TaskType,
  VoiceEmotion,
} from '@heygen/streaming-avatar';
import { RecordStreamService } from './record-stream.service';
import { StringUtils } from '@shared/utils/stringUtils';

@Injectable({
  providedIn: 'root',
})
export class TalkingAvatarService {
  protected streamDetails: Subject<MediaProvider | null> = new Subject();
  public isClosed = false;
  public config: TTalkingAvatarConfig | undefined;

  private subs: Subscription[] = [];
  private avatar: StreamingAvatar | undefined;
  private streamDetailsRawData: MediaProvider | null = null;
  protected textChunks = {
    startRecord: false,
    left: 0,
  };

  constructor(
    private _http: HttpClient,
    private _metadataService: MetadataService,
    private _recordService: RecordStreamService
  ) {}

  setSettings(bot: TBotItem): Promise<void> {
    this.config = undefined;
    this.streamDetails.next(null);
    return new Promise((resolve, reject) => {
      this._metadataService
        .get<string>(`${MetadataSlugs.TalkingAvatarSettings}_${bot._id}`)
        .subscribe({
          next: metadata => {
            this.config = JSON.parse(metadata.value);
            resolve();
          },
          error: err => reject(err),
        });
    });
  }

  getStreamDetailsAsObservable() {
    return this.streamDetails.asObservable();
  }

  isTalkingAvatarOff(): boolean {
    if (this.isClosed || this.config === undefined) {
      return true;
    }
    return !this.config.isAvailable;
  }

  init() {
    return new Promise(resolve => {
      if (this.isTalkingAvatarOff()) {
        resolve(true);
        return;
      }

      this.getToken().then(async token => {
        this.avatar = new StreamingAvatar({ token: token as string });
        await this.avatar.createStartAvatar({
          avatarName: this.config?.avatarId ?? 'default',
          voice: {
            emotion: this.config?.voiceEmotion ?? VoiceEmotion.BROADCASTER,
            rate: this.config?.voiceRate ?? 1,
          },
          quality: AvatarQuality.Low,
        });

        this.avatar!.on(StreamingEvents.STREAM_READY, handleStreamReady => {
          this.streamDetailsRawData = handleStreamReady.detail;
          this.streamDetails.next(this.streamDetailsRawData);
        });

        this.avatar!.on(StreamingEvents.AVATAR_START_TALKING, () => {
          if (this.textChunks.startRecord) {
            this.textChunks.startRecord = false;
            this._recordService.startRecording();
          }
        });

        this.avatar!.on(StreamingEvents.AVATAR_STOP_TALKING, () => {
          this.textChunks.left--;
          if (this.textChunks.left === 0) {
            this._recordService.stopRecording().then(() => {
              this.closeSession();
              this.streamDetails.next(null);
            });
          }
        });
        resolve(true);
      });
    });
  }

  private async getToken() {
    return new Promise((resolve, reject) => {
      const { url, body } = TalkingAvatarApi.getToken();
      const headers = new HttpHeaders().set(IGNORE_ERRORS_KEY, 'true');
      this.subs.push(
        this._http.post<{ token: string }>(url, body, { headers }).subscribe(value => {
          if (value.token) {
            resolve(value.token);
          } else {
            reject();
          }
        })
      );
    });
  }

  public setIds(conversationId: string, messageId: string) {
    this._recordService.setIds(conversationId, messageId);
  }

  async sendQuestion(question: string) {
    if (this.isTalkingAvatarOff()) {
      return;
    }
    if (!this.avatar) {
      await this.init();
    }

    const chunks = StringUtils.splitTextBySentences(question, 1400);

    this.textChunks.left = chunks.length;
    this.textChunks.startRecord = true;

    for (const chunk of chunks) {
      await this.avatar!.speak({
        text: chunk,
        task_type: TaskType.REPEAT,
      });
    }
  }

  async closeSession() {
    this.subs.forEach(sub => sub.unsubscribe());
    if (this.avatar) {
      await this.avatar!.stopAvatar();
      this.avatar = undefined;
    }
  }
}
