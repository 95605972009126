import { environment } from '@env/environment';

import { DeleteRequestData, GetRequestData, PostRequestData } from '../utils';
import {
  AddQuestionToConversationBody,
  ConversationStreamParams,
  InitStreamConversationBody,
  TTextProcessorBody,
} from './types';
import { TAskBotBody } from '@core/interfaces/TBot';

export class ConversationApi {
  static initStreamConversation(
    botId: string,
    body: InitStreamConversationBody
  ): PostRequestData<InitStreamConversationBody> {
    return {
      url: `${environment.backendURL}/api/bots/init/${botId}`,
      body,
    };
  }

  static conversationStream(params: ConversationStreamParams): GetRequestData {
    return {
      url: `${environment.backendURL}/api/bots/stream/${params.botId}/${params.conversationId}`,
    };
  }

  static askBot(params: ConversationStreamParams): PostRequestData<TAskBotBody> {
    return {
      url: `${environment.backendURL}/api/bots/ask/${params.botId}`,
      body: params.body,
    };
  }

  static addQuestionToConversation(
    botId: string,
    body: AddQuestionToConversationBody
  ): PostRequestData<AddQuestionToConversationBody> {
    return {
      url: `${environment.backendURL}/api/bots/addQuestion/${botId}`,
      body,
    };
  }

  static getLastAssistantMessage(conversationId: string): GetRequestData {
    return {
      url: `${environment.backendURL}/api/bots/conversations/getLastAssistantMessage/${conversationId}`,
    };
  }

  static starConversation(conversationId: string): PostRequestData {
    return {
      url: `${environment.backendURL}/api/conversations/favorite/${conversationId}`,
    };
  }

  static unstarConversation(conversationId: string): DeleteRequestData {
    return {
      url: `${environment.backendURL}/api/conversations/favorite/${conversationId}`,
    };
  }

  static starMessage(conversationId: string, messageId: string): PostRequestData {
    return {
      url: `${environment.backendURL}/api/conversations/favorite/${conversationId}/${messageId}`,
    };
  }

  static unstarMessage(conversationId: string, messageId: string): DeleteRequestData {
    return {
      url: `${environment.backendURL}/api/conversations/favorite/${conversationId}/${messageId}`,
    };
  }

  static updateAvatarVideoId(
    videoId: string,
    conversationId: string,
    messageId: string
  ): { body: { avatarVideo: string }; url: string } {
    return {
      url: `${environment.backendURL}/api/conversations/avatarVideo/${conversationId}/${messageId}`,
      body: { avatarVideo: videoId },
    };
  }

  static getVideosForConversation(conversationId: string): GetRequestData {
    return {
      url: `${environment.backendURL}/api/conversations/avatarVideo/${conversationId}`,
    };
  }

  static textProcessorForReferences(
    conversationId: string,
    messageId: string,
    text: string | string[]
  ): PostRequestData<TTextProcessorBody> {
    return {
      url: `${environment.backendURL}/api/textProcessor/process`,
      body: {
        conversationId,
        messageId,
        text,
      }
    };
  }
}
