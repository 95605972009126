import { Component, Input } from '@angular/core';
import { TSourceItem } from '@core/interfaces/TSourceItem';
import { SourcesService } from '@shared/services/sources.service';
import { TSourceEmbedding } from '@core/api/sources/types';
import { MatDialog } from '@angular/material/dialog';
import { EditReferencesModalComponent } from './edit-references-modal/edit-references-modal.component';

@Component({
  selector: 'app-source-embeddings',
  templateUrl: './source-embeddings.component.html',
  styleUrls: ['./source-embeddings.component.scss'],
})
export class SourceEmbeddingsComponent {
  protected source: TSourceItem | undefined;
  protected embeddings: TSourceEmbedding[] = [];

  constructor(
    private _sourcesService: SourcesService,
    public dialog: MatDialog
  ) {}

  @Input() set sourceItem(source: TSourceItem) {
    this.source = source;
    this._sourcesService.getEmbeddings(this.source!._id).subscribe(res => {
      this.embeddings = res;
    });
  }

  openReferenceModal(embedding: TSourceEmbedding) {
    this.dialog.open(EditReferencesModalComponent, {
      width: '100%',
      data: { source: this.source, embedding },
    });
  }
}
