<div>
  <app-quiz
    [currentMessage]="currentMessage"
    [bot]="bot"
    (dataLoaded)="handleQuizLoaded()"
  ></app-quiz>
  <app-conversation-input
    (filtersClick)="openFilters.emit($event)"
    *ngIf="!hideInput"
    [fixedToBottom]="true"
    [allowAttachFile]="true"
    [filterButton]="true"
    (messageSubmitted)="sendMessage($event.message, $event.files)"
  ></app-conversation-input>
</div>
