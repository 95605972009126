<mat-drawer-container class="gingr-content">
  <mat-drawer class="gingr-content__drawer" #drawer [opened]="drawerOpened" mode="side">
    <div class="gingr-content__drawer__header d-flex justify-content-between">
      <label>Results</label>
      <div class="d-flex top-buttons">
        <button mat-icon-button *ngIf="canSelectResults" (click)="openDeleteMultipleModal()">
          <mat-icon class="icon">delete</mat-icon>
        </button>
        <button mat-icon-button (click)="toggleSelectAll()">
          <mat-icon class="icon" [ngClass]="canSelectResults ? 'select-all' : ''"
            >select_all</mat-icon
          >
        </button>
      </div>
    </div>
    <div *ngIf="resultsLoading" class="d-flex align-items-center w-full">
      <mat-progress-spinner class="spinner" [color]="'accent'" [mode]="'indeterminate'">
      </mat-progress-spinner>
    </div>

    <ng-container *ngIf="!resultsLoading">
      <ul class="gingr-content__drawer-content gingr-content__drawer-content--grouped">
        <li *ngFor="let group of groups" class="gingr-content__drawer-content__group-item">
          <span>
            <span (click)="toggleGroup(group)" class="group-name">{{ group.name }}</span>
            <button class="group-name" mat-icon-button (click)="toggleGroup(group)">
              <mat-icon *ngIf="!group.isOpened">keyboard_arrow_left</mat-icon>
              <mat-icon *ngIf="group.isOpened">keyboard_arrow_down</mat-icon>
            </button>
          </span>

          <ul class="w-full" [ngClass]="getNestedListCSS(group)">
            <ng-container *ngIf="group.favorites?.length">
              <div class="m-b-8 m-t-8 d-flex justify-content-between">
                <span class="empty-list-indicator">Favorites</span>
                <span
                  *ngIf="canSelectResults"
                  class="d-flex gingr-content__drawer-content__item__sufix delete-checkbox"
                >
                  <mat-checkbox
                    (change)="toggleSelectGroup($event, group.favorites!)"
                    [(ngModel)]="groupsSelected.favorites"
                    [indeterminate]="isIndeterminate(group.favorites!)"
                  ></mat-checkbox>
                </span>
              </div>
              <li
                *ngFor="let item of group.favorites; let i = index"
                class="flex-row"
                [ngClass]="{ selected: selectedItem?._id === item._id }"
                (click)="selectItem(item, group.botType)"
              >
                <app-result-item
                  class="results-content-result-item"
                  [item]="item"
                  [groups]="groups"
                  (deletedItemEmiter)="afterDeleteItem($event)"
                  (selectedItemEmiter)="toggleSelectedItem(item)"
                  [canBeSelected]="canSelectResults"
                  [itemsSelection]="itemsSelection"
                ></app-result-item>
              </li>
            </ng-container>
            <ng-container *ngIf="group.todayItems?.length">
              <div class="m-b-8 m-t-8 d-flex justify-content-between">
                <span class="empty-list-indicator">Today</span>
                <span
                  *ngIf="canSelectResults"
                  class="d-flex gingr-content__drawer-content__item__sufix delete-checkbox"
                >
                  <mat-checkbox
                    (change)="toggleSelectGroup($event, group.todayItems!)"
                    [(ngModel)]="groupsSelected.todayItems"
                    [indeterminate]="isIndeterminate(group.todayItems!)"
                  ></mat-checkbox>
                </span>
              </div>
              <li
                *ngFor="let item of group.todayItems; let i = index"
                class="flex-row"
                [ngClass]="{ selected: selectedItem?._id === item._id }"
                (click)="selectItem(item, group.botType)"
              >
                <app-result-item
                  class="results-content-result-item"
                  [item]="item"
                  [groups]="groups"
                  (deletedItemEmiter)="afterDeleteItem($event)"
                  (selectedItemEmiter)="toggleSelectedItem(item)"
                  [canBeSelected]="canSelectResults"
                  [itemsSelection]="itemsSelection"
                ></app-result-item>
              </li>
            </ng-container>
            <ng-container *ngIf="group.yesterdayItems?.length">
              <div class="m-b-8 m-t-8 d-flex justify-content-between">
                <span class="empty-list-indicator">Yesterday</span>
                <span
                  *ngIf="canSelectResults"
                  class="d-flex gingr-content__drawer-content__item__sufix delete-checkbox"
                >
                  <mat-checkbox
                    (change)="toggleSelectGroup($event, group.yesterdayItems!)"
                    [(ngModel)]="groupsSelected.yesterdayItems"
                    [indeterminate]="isIndeterminate(group.yesterdayItems!)"
                  ></mat-checkbox>
                </span>
              </div>
              <li
                *ngFor="let item of group.yesterdayItems; let i = index"
                class="flex-row"
                [ngClass]="{ selected: selectedItem?._id === item._id }"
                (click)="selectItem(item, group.botType)"
              >
                <app-result-item
                  class="results-content-result-item"
                  [item]="item"
                  [groups]="groups"
                  (deletedItemEmiter)="afterDeleteItem($event)"
                  (selectedItemEmiter)="toggleSelectedItem(item)"
                  [canBeSelected]="canSelectResults"
                  [itemsSelection]="itemsSelection"
                ></app-result-item>
              </li>
            </ng-container>
            <ng-container *ngIf="group.last7DaysItems?.length">
              <div class="m-b-8 m-t-8 d-flex justify-content-between">
                <span class="empty-list-indicator">Previous 7 Days</span>
                <span
                  *ngIf="canSelectResults"
                  class="d-flex gingr-content__drawer-content__item__sufix delete-checkbox"
                >
                  <mat-checkbox
                    (change)="toggleSelectGroup($event, group.last7DaysItems!)"
                    [(ngModel)]="groupsSelected.last7DaysItems"
                    [indeterminate]="isIndeterminate(group.last7DaysItems!)"
                  ></mat-checkbox>
                </span>
              </div>
              <li
                *ngFor="let item of group.last7DaysItems; let i = index"
                class="flex-row"
                [ngClass]="{ selected: selectedItem?._id === item._id }"
                (click)="selectItem(item, group.botType)"
              >
                <app-result-item
                  class="results-content-result-item"
                  [item]="item"
                  [groups]="groups"
                  (deletedItemEmiter)="afterDeleteItem($event)"
                  (selectedItemEmiter)="toggleSelectedItem(item)"
                  [canBeSelected]="canSelectResults"
                  [itemsSelection]="itemsSelection"
                ></app-result-item>
              </li>
            </ng-container>
            <ng-container *ngIf="group.last30DaysItems?.length">
              <div class="m-b-8 m-t-8 d-flex justify-content-between">
                <span class="empty-list-indicator">Previous 30 Days</span>
                <span
                  *ngIf="canSelectResults"
                  class="d-flex gingr-content__drawer-content__item__sufix delete-checkbox"
                >
                  <mat-checkbox
                    (change)="toggleSelectGroup($event, group.last30DaysItems!)"
                    [(ngModel)]="groupsSelected.last30DaysItems"
                    [indeterminate]="isIndeterminate(group.last30DaysItems!)"
                  ></mat-checkbox>
                </span>
              </div>
              <li
                *ngFor="let item of group.last30DaysItems; let i = index"
                class="flex-row"
                [ngClass]="{ selected: selectedItem?._id === item._id }"
                (click)="selectItem(item, group.botType)"
              >
                <app-result-item
                  class="results-content-result-item"
                  [item]="item"
                  [groups]="groups"
                  (deletedItemEmiter)="afterDeleteItem($event)"
                  (selectedItemEmiter)="toggleSelectedItem(item)"
                  [canBeSelected]="canSelectResults"
                  [itemsSelection]="itemsSelection"
                ></app-result-item>
              </li>
            </ng-container>
            <ng-container *ngIf="group.olderItems?.length">
              <div class="m-b-8 m-t-8 d-flex justify-content-between">
                <span class="empty-list-indicator">Older</span>
                <span
                  *ngIf="canSelectResults"
                  class="d-flex gingr-content__drawer-content__item__sufix delete-checkbox"
                >
                  <mat-checkbox
                    (change)="toggleSelectGroup($event, group.olderItems!)"
                    [(ngModel)]="groupsSelected.olderItems"
                    [indeterminate]="isIndeterminate(group.olderItems!)"
                  ></mat-checkbox>
                </span>
              </div>
              <li
                *ngFor="let item of group.olderItems; let i = index"
                class="flex-row"
                [ngClass]="{ selected: selectedItem?._id === item._id }"
                (click)="selectItem(item, group.botType)"
              >
                <app-result-item
                  class="results-content-result-item"
                  [item]="item"
                  [groups]="groups"
                  (deletedItemEmiter)="afterDeleteItem($event)"
                  (selectedItemEmiter)="toggleSelectedItem(item)"
                  [canBeSelected]="canSelectResults"
                  [itemsSelection]="itemsSelection"
                ></app-result-item>
              </li>
            </ng-container>
            <div *ngIf="!group.groupItems.length" class="empty-list-indicator">
              No conversations
            </div>
          </ul>
        </li>
      </ul>
    </ng-container>
  </mat-drawer>
  <mat-drawer-content class="gingr-content__drawer-content">
    <ng-container *ngIf="selectedItem">
      <app-bot-results
        [botId]="selectedItem.botID"
        [showTalkingAvatar]="true"
        *ngIf="!isBotSelected"
        [toggleStarCallback]="toggleStarConversationCallback.bind(this)"
      ></app-bot-results>
      <app-bots-content *ngIf="isBotSelected" [selectedBot]="selectedItem"></app-bots-content>
    </ng-container>

    <button class="drawer-toggle" color="secondary" mat-raised-button (click)="toggleDrawer()">
      <mat-icon *ngIf="drawerOpened">keyboard_arrow_left</mat-icon>
      <mat-icon *ngIf="!drawerOpened">keyboard_arrow_right</mat-icon>
    </button>
  </mat-drawer-content>
</mat-drawer-container>
