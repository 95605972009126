import { Component, EventEmitter, Inject, OnDestroy, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TSourceItem } from '@core/interfaces/TSourceItem';
import { TExternalReference } from '@core/api/sources/types';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SourcesService } from '@shared/services/sources.service';

@Component({
  selector: 'app-external-reference-form-modal',
  templateUrl: './external-reference-form-modal.component.html',
  styleUrls: ['./external-reference-form-modal.component.scss'],
})
export class ExternalReferenceFormModalComponent implements OnDestroy {
  protected isLoading = true;

  referenceForm: FormGroup;
  private _subs: Subscription[] = [];

  @Output() referenceChanged = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { source: TSourceItem; reference: TExternalReference },
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ExternalReferenceFormModalComponent>,
    private _sourcesService: SourcesService
  ) {
    this.referenceForm = this.fb.group({
      referenceText: [
        this.data.reference?.referenceText ?? '',
        [Validators.required, Validators.minLength(3)],
      ],
      referenceLink: [
        this.data.reference?.referenceLink ?? '',
        [Validators.required, Validators.minLength(3)],
      ],
    });
  }

  protected cancel(): void {
    this.dialogRef.close();
  }

  protected submit(): void {
    if (this.referenceForm.valid) {
      if (this.data.reference?._id) {
        this.updateReference();
      } else {
        this.createReference();
      }
    }
  }

  private createReference() {
    const reference: TExternalReference = this.referenceForm.value as TExternalReference;
    this._subs.push(
      this._sourcesService.addExternalReference(this.data.source!._id, reference).subscribe(() => {
        this.referenceForm.reset();
        this.referenceChanged.emit();
      })
    );
  }

  private updateReference() {
    const reference: TExternalReference = this.referenceForm.value as TExternalReference;
    reference._id = this.data.reference._id;
    this._subs.push(
      this._sourcesService
        .updateExternalReference(this.data.source!._id, reference)
        .subscribe(() => {
          this.cancel();
          this.referenceChanged.emit();
        })
    );
  }

  ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }
}
