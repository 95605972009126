import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { IGNORE_ERRORS_KEY, TokenService } from '@core';
import { ConversationApi } from '@core/api/conversations/api';
import { TSourceDetail } from '@core/interfaces/TMessage';
import { fetchEventSource } from '@microsoft/fetch-event-source';

import {
  AddQuestionToConversationBody,
  ConversationStreamParams,
  InitStreamConversationBody,
  InitStreamConversationResponse,
  TConversationStreamData, TConversationVideos,
  TReferencesStream,
} from '@core/api/conversations/types';
import { TSourceMaterial } from '@core/api/materials/types';
import { GetRequestData, PostRequestData } from '@core/api/utils';
import { QuizQuestion, TAskBotBody } from '@core/interfaces/TBot';

@Injectable({
  providedIn: 'root',
})
export class ConversationsService {
  zone = new NgZone({ enableLongStackTrace: false });
  private isSearching: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isWriting: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private errorOccurred: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private conversationId: BehaviorSubject<string|null> = new BehaviorSubject<string|null>(null);
  private currentConversationStream: BehaviorSubject<TConversationStreamData|null> =
    new BehaviorSubject<TConversationStreamData|null>(null);
  private sourceDetails: BehaviorSubject<TSourceDetail[]> = new BehaviorSubject<TSourceDetail[]>(
    []
  );
  private cancelStreamController: AbortController|null = null;
  private countdown: NodeJS.Timeout|undefined;
  private eventTimeoutInSec = 30;

  constructor(
    private _http: HttpClient,
    private tokenService: TokenService
  ) {}

  public getConversationIdObservable(): Observable<string|null> {
    return this.conversationId.asObservable();
  }

  public getIsSearchingObservable(): Observable<boolean> {
    return this.isSearching.asObservable();
  }

  public getIsWritingObservable(): Observable<boolean> {
    return this.isWriting.asObservable();
  }

  public getCurrentConversationObservable(): Observable<TConversationStreamData|null> {
    return this.currentConversationStream.asObservable();
  }

  public getSourceDetailsObservable(): Observable<TSourceDetail[]> {
    return this.sourceDetails.asObservable();
  }

  public getErrorOccurredAsObservable(): Observable<string> {
    return this.errorOccurred.asObservable();
  }

  public setTimeout(seconds: number) {
    this.eventTimeoutInSec = seconds;
  }

  public resetConversationId() {
    this.conversationId.next(null);
  }

  public initStreamConversation(
    botId: string,
    bodyParams: InitStreamConversationBody,
    files: File[] = [],
    callback?: (response: InitStreamConversationResponse) => void
  ): void {
    this.isSearching.next(true);
    const { url, body } = ConversationApi.initStreamConversation(botId, bodyParams);

    const formData = this.convertToFormData(body!, files);
    const response = this._http.post<InitStreamConversationResponse>(url, formData);

    response.subscribe({
      error: err => {
        console.error(err);
        this.isSearching.next(false);
        this.errorOccurred.next('An error occurred. Please try again.');
      },
      next: response => {
        this.isSearching.next(false);
        this.conversationId.next(response.conversation_id);
        this.sourceDetails.next(response.sourceDetails);
        if (callback) callback(response);
      },
    });
  }

  protected convertToFormData(body: InitStreamConversationBody, files: File[] = []): FormData {
    const formData = new FormData();
    Object.keys(body).forEach(key => {
      const value = (body as any)[key];
      if (Array.isArray(value)) {
        value.forEach((item, index) => formData.append(`${key}[${index}]`, item));
      } else if (value !== undefined && value !== null) {
        formData.append(key, value);
      }
    });
    files.forEach((file: File) => {
      formData.append('files', file);
    });
    return formData;
  }

  public addQuestionToConversation(
    botId: string,
    bodyParams: AddQuestionToConversationBody,
    callback?: (conversationId: string, messageId: string) => void
  ): void {
    this.isSearching.next(true);
    const { url, body } = ConversationApi.addQuestionToConversation(botId, bodyParams);
    const response = this._http.post<{ conversation_id: string; message_id: string }>(url, body);
    response.subscribe({
      error: err => {
        console.error(err);
        this.errorOccurred.next('An error occurred. Please try again.');
        this.isSearching.next(false);
      },
      next: resp => {
        this.isSearching.next(false);
        if (callback) callback(resp.conversation_id, resp.message_id);
      },
    });
  }

  public cancelStreamConversation(): void {
    if (this.cancelStreamController) {
      this.cancelStreamController.abort();
      this.isWriting.next(false);
    }
  }

  public getConversationAsStream(params: ConversationStreamParams, callback?: () => void) {
    this.getStream(params, ConversationApi.conversationStream(params), callback);
  }

  public getAskBotAsStream(params: ConversationStreamParams, callback?: () => void) {
    this.getStream(params, ConversationApi.askBot(params), callback);
  }

  protected startCountdown(): void {
    if (this.countdown) {
      this.clearCountdown();
    }
    this.countdown = setTimeout(() => {
      this.errorOccurred.next('Bot has stopped responding. Please try again.');
      this.isWriting.next(false);
      this.cancelStreamConversation();
    }, this.eventTimeoutInSec * 1000);
  }

  clearCountdown() {
    clearTimeout(this.countdown);
  }

  protected getStream(
    params: ConversationStreamParams,
    connection: GetRequestData | PostRequestData<TAskBotBody>,
    callback?: () => void
  ) {
    this.cancelStreamConversation();
    this.currentConversationStream.next(null);
    const controller = new AbortController();
    const { signal } = controller;
    this.cancelStreamController = controller;

    this.isWriting.next(true);
    this.startCountdown();

    const headers: HeadersInit = {
      Authorization: this.tokenService.getPlainToken(),
      Accept: 'text/event-stream',
      Connection: 'keep-alive',
    };

    let body: BodyInit | null = null;

    if (params.body) {
      if ('files' in params.body && params.body.files) {
        const formData = new FormData();
        formData.append('body', JSON.stringify(params.body));
        params.body.files.forEach((file, index) => {
          formData.append('files', file);
        });
        body = formData;
      } else {
        headers['Content-Type'] = 'application/json';
        body = JSON.stringify(params.body);
      }
    }

    fetchEventSource(connection.url, {
      headers,
      body,
      method: params.body ? 'POST' : 'GET',
      cache: 'no-cache',
      openWhenHidden: true,
      onmessage: ev => {
        this.currentConversationStream.next(this.deserializeEventData(ev.data));
        this.startCountdown();
      },
      onerror: error => {
        this.isWriting.next(false);
        this.errorOccurred.next('An error occurred. Please try again.');
        this.clearCountdown();
        console.error(error);
      },
      onclose: () => {
        this.isWriting.next(false);
        this.clearCountdown();
        if (callback) callback();
      },
      signal,
    });
  }

  deserializeEventData(message: string): TConversationStreamData {
    let chunk: object = {};

    try {
      chunk = JSON.parse(message);
    } catch (e) {
      console.error(message, e);
      return { type: 'message', data: '' };
    }

    if ('message_id' in chunk) {
      return { type: 'message_id', data: <string>chunk.message_id };
    }
    if ('json_answer' in chunk) {
      return { type: 'json_answer', data: chunk.json_answer as QuizQuestion[] };
    }
    if ('message' in chunk) {
      return { type: 'message', data: <string>chunk.message };
    }
    if ('sourceDetails' in chunk) {
      return { type: 'sourceDetails', data: chunk.sourceDetails as TSourceDetail[] };
    }
    if ('references' in chunk) {
      return { type: 'reference', data: chunk.references as TReferencesStream[] };
    }
    if ('conversation_id' in chunk) {
      this.conversationId.next(chunk.conversation_id as string);
    }

    return { type: 'material', data: chunk as TSourceMaterial };
  }

  starConversation(conversationId: string, callback: () => void): void {
    const { url } = ConversationApi.starConversation(conversationId);
    this._http.post(url, {}).subscribe({
      error: err => {
        this.errorOccurred.next('An error occurred. Please try again.');
        console.error(err);
      },
      next: () => callback(),
    });
  }

  starMessage(conversationId: string, messageId: string, callback: () => void): void {
    const { url } = ConversationApi.starMessage(conversationId, messageId);
    this._http.post(url, {}).subscribe({
      error: err => console.error(err),
      next: () => callback(),
    });
  }

  unstarConversation(conversationId: string, callback: () => void): void {
    const { url } = ConversationApi.unstarConversation(conversationId);
    this._http.delete(url).subscribe({
      error: err => console.error(err),
      next: () => callback(),
    });
  }

  unstarMessage(conversationId: string, messageId: string, callback: () => void): void {
    const { url } = ConversationApi.unstarMessage(conversationId, messageId);
    this._http.delete(url).subscribe({
      error: err => console.error(err),
      next: () => callback(),
    });
  }

  updateAvatarVideo(videoId: string, conversationId: string, messageId: string) {
    const { url, body } = ConversationApi.updateAvatarVideoId(videoId, conversationId, messageId);
    return this._http.post(url, body);
  }

  getVideosForConversation(conversationId: string): Observable<TConversationVideos> {
    const headers = new HttpHeaders().set(IGNORE_ERRORS_KEY, 'true');
    const { url } = ConversationApi.getVideosForConversation(conversationId);
    return this._http.get<TConversationVideos>(url, { headers });
  }

  getReferences(conversationId: string, messageId: string, text: string | string[]): Observable<any> {
    const { url, body } = ConversationApi.textProcessorForReferences(conversationId, messageId, text);
    return this._http.post(url, body);
  }
}
