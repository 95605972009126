import { ConversationData } from '@core/interfaces/TResultsItem';
import { TAdaptedBotConversation, TAdaptedReference } from '../types/types';
import { BaseAdapter } from '@shared/services/bots/adapters/BaseAdapter';
import { ArrayUtils } from '@shared/utils/arrayUtils';
import { TSourceDetail } from '@core/interfaces/TMessage';

export class ReferenceAdapter extends BaseAdapter {
  adapt(data: ConversationData, currentValue: TAdaptedBotConversation): TAdaptedBotConversation {
    const lastMessage = ArrayUtils.last(currentValue?.messages);

    if (lastMessage) {
      lastMessage.sources = data.sources?.map(source => ({
        _id: source.id,
        fileLink: source.fileLink,
        name: source.name,
        referenceIndexes: new Map<number, string>(),
      })) as TSourceDetail[];

      const embeddingIndexDictionary = new Map<string, number>();

      data.processedTexts?.forEach((processedTxt, index) => {
        lastMessage.paragraphs[index].text = processedTxt.segment;

        processedTxt.references.forEach(reference => {
          const sourceIndex = lastMessage.sources.findIndex(
            source => source._id === reference.sourceId
          );

          if (!embeddingIndexDictionary.has(reference.embeddingId)) {
            embeddingIndexDictionary.set(reference.embeddingId, embeddingIndexDictionary.size + 1);
          }

          const adoptedReference = {
            order: embeddingIndexDictionary.get(reference.embeddingId),
            source: { ...lastMessage.sources[sourceIndex] } || ({} as TSourceDetail),
          } as TAdaptedReference;

          adoptedReference.source.embeddingContent = reference.originalText;

          if (!lastMessage.paragraphs[index].references) {
            lastMessage.paragraphs[index].references = [];
          }

          lastMessage.paragraphs[index].references?.push(adoptedReference);

          const sourceDetail = lastMessage.sources[sourceIndex];
          const refIndex = embeddingIndexDictionary.get(reference.embeddingId);
          if (refIndex !== undefined) {
            sourceDetail.referenceIndexes!.set(refIndex, reference.originalText);
          }
        });
        lastMessage.paragraphs[index].references?.sort((a, b) => a.order - b.order);
      });
    }

    return currentValue;
  }
}
