<div class="modal">
  <h3>{{ StringUtils.getFirstSentence(data.embedding.content) }}</h3>
  <app-source-references
    [sourceItem]="data.source"
    [sourceEmbedding]="data.embedding"
  >
  </app-source-references>
  <div class="d-flex align-items-center justify-content-between m-t-16">
    <button
      mat-raised-button
      color="accent"
      (click)="cancel()">
      Cancel
    </button>
  </div>
</div>
