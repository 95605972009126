import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SourcesService } from '@shared/services/sources.service';
import { TSourceItem } from '@core/interfaces/TSourceItem';
import { TExternalReference } from '@core/api/sources/types';

@Component({
  selector: 'app-source-references-search',
  templateUrl: './source-references-search.component.html',
  styleUrls: ['./source-references-search.component.scss'],
})
export class SourceReferencesSearchComponent implements OnInit {
  @Input() source: TSourceItem | undefined;
  @Output() results = new EventEmitter<TExternalReference[]>();
  searchForm: FormGroup;

  constructor(
    private sourcesService: SourcesService,
    private fb: FormBuilder
  ) {
    this.searchForm = this.fb.group({
      referenceText: [''],
    });
  }

  ngOnInit(): void {
    this.searchForm
      .get('referenceText')
      ?.valueChanges.pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(value => {
        this.search(value);
      });
  }

  onSearch() {
    this.search(this.searchForm.get('referenceText')?.value);
  }

  onReset() {
    this.searchForm.reset();
    this.search('');
  }

  search(query: string): void {
    this.sourcesService.searchExternalReference(this.source!._id, query).subscribe(res => {
      this.results.emit(res);
    });
  }
}
