<div class="d-flex align-items-center ">
  <button mat-icon-button (click)="back()" color="accent" class="m-r-8">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <h3 class="mat-h3 m-y-16">{{ source.name }}</h3>
</div>

<div *ngIf="source.modifiedDate">
  <span class="light">Last modified {{ getDate(source.modifiedDate) }}</span>
</div>
<div *ngIf="source.addedDate" class="m-b-24">
  <span class="light">Created {{ getDate(source.addedDate) }}</span>
</div>

<mat-tab-group color="accent">

  <mat-tab label="General">
    <mat-card>
      <mat-card-content>
        <app-sources-content
          [selectedItem]="source"
          (handleCancel)="back()"
        ></app-sources-content>
      </mat-card-content>
    </mat-card>
  </mat-tab>

  <mat-tab label="Embedings">
    <mat-card>
      <mat-card-content>
        <app-source-embeddings
          [sourceItem]="source"
        >
        </app-source-embeddings>
      </mat-card-content>
    </mat-card>
  </mat-tab>


  <mat-tab label="References">
    <mat-card>
      <mat-card-content>
        <app-source-references
          [sourceItem]="source"
        >
        </app-source-references>
      </mat-card-content>
    </mat-card>
  </mat-tab>

</mat-tab-group>


