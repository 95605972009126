<div class="gingr-input-wrapper gingr-input-wrapper--with-form search-wrapper">
  <form [formGroup]="searchForm" >
    <input
      class="gingr-input--primary"
      matInput
      placeholder="Search references"
      formControlName="referenceText"
      autocomplete="off"
    />
  </form>
  <div class="search-buttons">
    <button mat-icon-button (click)="onReset()">
      <mat-icon>close</mat-icon>
    </button>
    <button mat-icon-button (click)="onSearch()">
      <mat-icon>search</mat-icon>
    </button>
  </div>
</div>

