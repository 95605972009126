<table mat-table [dataSource]="embeddings">

  <!-- Embedding Name Column -->
  <ng-container matColumnDef="content">
    <th mat-header-cell *matHeaderCellDef> Content </th>
    <td mat-cell *matCellDef="let embedding"> {{ embedding.content }} </td>
  </ng-container>

  <!-- Embedding Value Column -->
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef> Status </th>
    <td mat-cell *matCellDef="let embedding"> {{ embedding.status }} </td>
  </ng-container>

  <!-- Embedding Actions -->
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> Actions </th>
    <td mat-cell *matCellDef="let embedding">
      <button (click)="openReferenceModal(embedding)" mat-flat-button color="accent">References</button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="['content', 'status', 'actions'];"></tr>
  <tr mat-row *matRowDef="let row; columns: ['content', 'status', 'actions'];"></tr>
</table>
