import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BotMessagesService } from '@shared/services/bots/bot-messages.service';
import { TAdaptedBotConversation, TAdaptedMessage } from '@shared/services/bots/types/types';
import { ConversationsService } from '@shared/services/conversations.service';
import { Subscription } from 'rxjs';
import { BotsService } from '@shared/services/bots.service';
import { ArrayUtils } from '@shared/utils/arrayUtils';
import { TBotItem } from '@core/interfaces/TBotItem';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bot-results',
  templateUrl: './bot-results.component.html',
  styleUrls: ['./bot-results.component.scss'],
})
export class BotResultsComponent implements OnInit, OnDestroy {
  @Input() toggleStarCallback: ((cId: string) => void) | undefined;
  @Input() bot: TBotItem | null = null;
  @Input() botId: string | null = null;
  @Input() showTalkingAvatar = false;
  protected adaptedBotConversation: TAdaptedBotConversation | null = null;
  private _subs: Subscription[] = [];
  public conversationId: string | null = null;
  public canStar = false;
  public botFinalMessage: string | null = null;

  constructor(
    protected botMessagesService: BotMessagesService,
    protected conversationsService: ConversationsService,
    private _botsService: BotsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this._botsService.fetchBotFinalMessage();
    this._subs.push(
      this._botsService.getBotFinalMessageObservable().subscribe(res => {
        this.botFinalMessage = res;
      })
    );

    this._subs.push(
      this.botMessagesService.adaptedBotConversationObservable.subscribe(conv => {
        this.adaptedBotConversation = conv;
        this.canStar = Boolean(this.adaptedBotConversation?.id || this.conversationId);
      })
    );

    this._subs.push(
      this.conversationsService.getConversationIdObservable().subscribe(r => {
        this.conversationId = r;
        this.canStar = Boolean(this.adaptedBotConversation?.id || this.conversationId);
      })
    );
  }

  trackByMessage(index: number, message: TAdaptedMessage): number {
    const lastParagraph = ArrayUtils.last(message.paragraphs);
    return (
      (lastParagraph?.references?.length ?? 0) +
      (lastParagraph?.text.length ?? 0) +
      message.paragraphs.length +
      message.sources.length
    );
  }

  ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }

  toggleStarConversation() {
    const cId = this.adaptedBotConversation?.id || this.conversationId;
    if (!cId) {
      return;
    }

    const toggle = () => {
      this.adaptedBotConversation!.favorite = !this.adaptedBotConversation!.favorite;
      if (this.toggleStarCallback) this.toggleStarCallback(cId);
    };

    if (this.adaptedBotConversation!.favorite) {
      this.conversationsService.unstarConversation(cId, toggle);
    } else {
      this.conversationsService.starConversation(cId, toggle);
    }
  }

  continueConversation(message: string) {
    const cID = this.adaptedBotConversation?.id || this.conversationId;
    if (!cID || !this.botId) {
      return;
    }
    this.router.navigate([`/bots`, this.botId], { queryParams: { cID, message } });
  }
}
