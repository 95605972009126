<mat-drawer-container class="gingr-content">
  <mat-drawer class="gingr-content__drawer" #drawer [opened]="drawerOpened" mode="side">
    <div style="position: relative">
      <button mat-icon-button (click)="addNewItem()" color="accent" class="add-button">
        <mat-icon>add_box</mat-icon>
      </button>

      <mat-tab-group
        [(selectedIndex)]="selectedTab" (selectedTabChange)="tabChanged($event)"
        animationDuration="0ms"
        color="accent"
        mat-stretch-tabs="false"
        mat-align-tabs="start"
      >
        <mat-tab label="Sources">
          <app-spinner *ngIf="foldersLoading" [message]="''"></app-spinner>
          <ul
            class="gingr-content__drawer-content gingr-content__drawer-content--items"
            *ngIf="!foldersLoading"
          >
            <li
              *ngFor="let item of folders"
              class="gingr-content__drawer-content__item source-list-item"
              [ngClass]="{ selected: selectedFolder?._id === item._id }"
            >
              <span class="gingr-content__drawer-content__item" (click)="goToFolder(item)">
                <ng-container *ngIf="hasIcon(item)">
                  <ng-container *ngIf="isCustomIcon(item)">
                    <mat-icon
                      class="menu-icon"
                      [ngClass]="getItemCss(item)"
                      svgIcon="{{ item.icon }}"
                    ></mat-icon>
                  </ng-container>
                  <ng-container *ngIf="!isCustomIcon(item)">
                    <mat-icon class="menu-icon" [ngClass]="getItemCss(item)">{{
                        item.icon
                      }}</mat-icon>
                  </ng-container>
                </ng-container>

                <div class="item-title" appTextEllipsis>{{ item.title }}</div>
                <span *ngIf="hasSuffix(item)" class="gingr-content__drawer-content__item__sufix">
                  {{ item.sufix }}
                </span>
              </span>
              <span class="d-flex gingr-content__drawer-content__item__sufix delete-icon">
                <mat-icon class="icon" (click)="openDeleteItemModal(item)">delete</mat-icon>
              </span>
            </li>
          </ul>
        </mat-tab>
        <mat-tab label="Materials">
          <app-materials-folders></app-materials-folders>
        </mat-tab>
      </mat-tab-group>
    </div>
  </mat-drawer>
  <mat-drawer-content class="gingr-content__drawer-content">
    <ng-container *ngIf="selectedTab === Tabs.sources">
      <ng-container *ngIf="selectedSource">
<!--        <app-sources-content-->
<!--          [selectedItem]="selectedSource"-->
<!--          (handleCancel)="handleCloseSource($event)"-->
<!--        ></app-sources-content>-->
        <app-sources-settings
          [source]="selectedSource"
          (handleCloseSource)="handleCloseSource($event)"
        >
        </app-sources-settings>
      </ng-container>
      <ng-container *ngIf="selectedFolder && !selectedSource">
        <app-folders-content
          [selectedItem]="selectedFolder"
          (sourceSelected)="handleSourceSelected($event)"
          [returnedFromSource]="returnedFromSource"
        ></app-folders-content>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="selectedTab === Tabs.materials">
      <app-materials-list
      ></app-materials-list>
    </ng-container>

    <button class="drawer-toggle" color="secondary" mat-raised-button (click)="toggleDrawer()">
      <mat-icon *ngIf="drawerOpened">keyboard_arrow_left</mat-icon>
      <mat-icon *ngIf="!drawerOpened">keyboard_arrow_right</mat-icon>
    </button>
  </mat-drawer-content>
</mat-drawer-container>
