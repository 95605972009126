import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TSourceItem } from '@core/interfaces/TSourceItem';
import { TSourceEmbedding } from '@core/api/sources/types';
import { StringUtils } from '@shared/utils/stringUtils';

@Component({
  selector: 'app-edit-references-modal',
  templateUrl: './edit-references-modal.component.html',
  styleUrls: ['./edit-references-modal.component.scss'],
})
export class EditReferencesModalComponent {
  protected isLoading = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { source: TSourceItem; embedding: TSourceEmbedding },
    private dialogRef: MatDialogRef<EditReferencesModalComponent>
  ) {
  }

  protected cancel(): void {
    this.dialogRef.close();
  }

  protected readonly StringUtils = StringUtils;
}
