import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TSourceItem } from '@core/interfaces/TSourceItem';

@Component({
  selector: 'app-sources-settings',
  templateUrl: './sources-settings.component.html',
  styleUrls: ['./sources-settings.component.scss'],
})
export class SourcesSettingsComponent {
  @Input() source: TSourceItem = {} as TSourceItem;
  @Output() handleCloseSource = new EventEmitter();

  public getDate(date: Date | undefined): string {
    const formattedDate = Date.parse(date as unknown as string);
    if (date && isNaN(formattedDate ?? NaN)) {
      return `Invalid date`;
    }
    return new Intl.DateTimeFormat('en-US').format(formattedDate);
  }

  public back(): void {
    if (this.source.folder[0]) {
      this.handleCloseSource.emit(this.source.folder[0]);
    }
  }
}
