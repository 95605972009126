<div class="video-container" (mouseenter)="controlsAreShown = true" (mouseleave)="controlsAreShown = false">
  <div>
    <video
      #videoPlayer
      autoplay
      playsinline
      [width]="videoDimensions.width"
      [height]="videoDimensions.height"
    >
    </video>
  </div>

  <ng-template *ngIf="controlsAreShown">
    <button *ngIf="!hideControls" class="play-pause-btn" (click)="togglePlayPause()">
      <mat-icon>{{ isPlaying ? 'pause' : 'play_arrow' }}</mat-icon>
    </button>
  </ng-template>

</div>
