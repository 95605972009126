import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TBotItem } from '@core/interfaces/TBotItem';
import { CommonService } from '@shared/services/common.service';
import { ConversationsService } from '@shared/services/conversations.service';
import { Subscription } from 'rxjs';
import { BotMessagesService } from '@shared/services/bots/bot-messages.service';
import { QuizBotAnswersAdapter } from '@shared/services/bots/adapters/QuizBotAnsersAdapter';
import { TSourceDetail } from '@core/interfaces/TMessage';
import { BotConversationAdapter } from '@shared/services/bots/adapters/BotConversationAdapter';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
})
export class QuizComponent implements OnChanges, OnInit, OnDestroy {
  @Input() currentMessage: { text: string, files: File[] } | null = null;
  @Input() bot: TBotItem = {} as TBotItem;
  @Output() public dataLoaded = new EventEmitter();

  isLoading = false;
  isGettingFeedback = false;
  conversationId: string | null = null;
  isFeedbackAllowed = true;
  private _subs: Subscription[] = [];
  private sourceDetails: TSourceDetail[] = [];
  private currentContent = '';
  protected maxQuestions = 4;

  constructor(
    private _commonService: CommonService,
    private _conversationsService: ConversationsService,
    private botMessageService: BotMessagesService
  ) {
    this._conversationsService.setTimeout(240);
    this.botMessageService.reset();
    this._conversationsService.resetConversationId();
  }

  ngOnInit() {
    this.isGettingFeedback = false;
    this.isFeedbackAllowed = true;
    this._subs.push(
      this._conversationsService.getErrorOccurredAsObservable().subscribe(error => {
        if (error) {
          this.botMessageService.addErrorMessage(error);
        }
      })
    );
    this._subs.push(
      this._conversationsService.getIsWritingObservable().subscribe(res => {
        this.isLoading = res;
        this.botMessageService.toggleIsWritingStatus(res);
      })
    );
    this._subs.push(
      this._conversationsService.getCurrentConversationObservable().subscribe(res => {
        if (res) {
          if (res.type === 'sourceDetails') {
            this.sourceDetails = res.data;
          }
          this.botMessageService.appendMessageViaAdapter(res, this.sourceDetails);
        }
      })
    );

    this._subs.push(
      this._conversationsService.getConversationIdObservable().subscribe(res => {
        if (res) {
          this.conversationId = res;
        }
      })
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.currentMessage?.previousValue !== changes.currentMessage?.currentValue &&
      this.currentMessage?.text
    ) {
      this.startQuiz();
    }
  }

  public startQuiz(): void {
    if (!this.currentMessage) return;
    this.botMessageService.addMessage('user', this.currentMessage!.text);
    this.botMessageService.addMessage('assistant', '');
    this.botMessageService.setAdapter(new BotConversationAdapter());
    this._conversationsService.getAskBotAsStream(
      {
        botId: this.bot._id,
        body: { question: this.currentMessage.text, request_type: 'get_init_message', files: this.currentMessage.files, },
      },
      () => {
        this.currentContent =
          this.botMessageService.getLastMessageByAuthor('assistant').paragraphs[0].text;
        this.getQuestions(this.currentContent);
      }
    );
  }

  private async getQuestions(content: string) {
    this.botMessageService.addMessage('assistant', '', undefined, undefined, 'en', 'quiz');
    this.botMessageService.setAdapter(new QuizBotAnswersAdapter());

    let trialsLeft = 5;
    this.maxQuestions--;
    while (trialsLeft > 0) {
      await this.handleGetQuestionRequest(content)
        .then(() => {
          trialsLeft = 0;
        })
        .catch(() => {
          trialsLeft--;
          this._commonService.openSnackBar('This take a little longer than usual. Please wait...');
          if (trialsLeft === 0) {
            this.botMessageService.addErrorMessage();
          }
        });
    }
  }

  private handleGetQuestionRequest(content: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this._conversationsService.getAskBotAsStream(
        {
          botId: this.bot._id,
          conversationId: this.conversationId!,
          body: {
            content,
            request_type: 'get_question',
            conversation_id: this.conversationId!,
          },
        },
        () => {
          const lastMsg = this.botMessageService.getLastMessage();
          if (lastMsg?.paragraphs[0]?.quizQuestions?.length === 0) {
            reject();
            return;
          }
          resolve(true);
        }
      );
    });
  }

  public getMoreQuestions(): void {
    this.isFeedbackAllowed = true;
    this.getQuestions(this.currentContent);
  }

  protected getFeedback() {
    this.isGettingFeedback = true;
    this.isFeedbackAllowed = false;

    this.botMessageService.setAdapter(new BotConversationAdapter());
    this.botMessageService.addMessage('assistant', '');

    this._conversationsService.getAskBotAsStream(
      {
        botId: this.bot._id,
        body: { conversation_id: this.conversationId!, request_type: 'get_feedback' },
      },
      () => {
        this.isGettingFeedback = false;
      }
    );
  }

  ngOnDestroy() {
    this._subs.forEach(sub => sub.unsubscribe());
  }
}
