import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TBotItem } from '@core/interfaces/TBotItem';

@Component({
  selector: 'app-quiz-bot',
  templateUrl: './quiz-bot.component.html',
  styleUrls: ['./quiz-bot.component.scss'],
})
export class QuizBotComponent {
  @Input() bot: TBotItem = {} as TBotItem;
  @Output() openFilters = new EventEmitter<boolean>();
  currentMessage: { text: string, files: File[] } | null = null;
  isLoading = false;
  protected hideInput= false;

  public sendMessage(msg: string, files: File[]): void {
    if (!msg) return;
    this.isLoading = true;
    this.hideInput = true;
    this.currentMessage = {
      text: msg,
      files,
    };
  }

  public handleQuizLoaded(): void {
    this.isLoading = false;
  }
}
